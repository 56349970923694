var isOnMobile;

jQuery(function($){
  var mobileBreakpoint = 768;

  /**
   * @function isOnMobile
   * @description indicates if current display is on mobile support.
   */
  isOnMobile = function () {
    return ($(window).width() < mobileBreakpoint);
  }
});