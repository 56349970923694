jQuery.fn.extend({
  load: function() {
    return jQuery(this).each(function() {
      var childSpan = jQuery(this).find('span:visible');
      var currentLabel = childSpan.text();

      jQuery(this).data('current',currentLabel);

      jQuery(this).addClass('disabled').prop('disabled', true);

      if (jQuery(this).data('loading')) {
        jQuery(this).find('span').text(jQuery(this).data('loading'));
      }
    });
  },
  reset: function() {
    return jQuery(this).each(function() {
      jQuery(this).removeClass('disabled').prop('disabled', false);
      jQuery(this).find('span').text(jQuery(this).data('current'));
    });
  }
});