var validateField;
var validateCheckboxes;
var getPasswordStrength;

jQuery(function($){
  /**
   * @function displayFieldError
   * @description display field as invalid and show its alert.
   * @param {object} field - Field to display as invalid.
   * @param {string} feedback - Data attributes suffix containing text to display.
   */
  var displayFieldError = function (field, feedback) {
    if (!feedback) feedback = 'default';

    field.addClass('is-invalid');
 
    displayInvalidFeedback(field, feedback);
  }
  
  /**
   * @function resetFieldError
   * @description reset invalid state for field.
   * @param {object} field - Field to reset.
   */
  var resetFieldError = function  (field) {
    field.removeClass('is-invalid');
  }
  
  /**
   * @function validateInputField
   * @description validate field of type <input type="text">
   * @param {object} field - Field to validate.
   */
  var validateInputField = function (field) {
    // Check value for required field.
    if (!field.val() && field.prop('required')) {
      displayFieldError(field);

      return false;
    }
  
    resetFieldError(field);
  
    return true;
  }
  
  /**
   * @function validateSelectField
   * @description validate field of type <select>
   * @param {object} field - Field to validate.
   */
  var validateSelectField = function (field) {
    // Check value for required field.
    if (!field.val() && field.prop('required')) {
      displayFieldError(field.parents('.select'));

      return false;
    }
  
    resetFieldError(field);
  
    return true;
  }
  
  /**
   * @function validateTextareaField
   * @description validate field of type <textarea>
   * @param {object} field - Field to validate.
   */
  var validateTextareaField = function (field) {
    // Check value for required field.
    if (!field.val() && field.prop('required')) {
      displayFieldError(field);
  
      return false;
    }
  
    resetFieldError(field);
  
    return true;
  }
  
  /**
   * @function validateURLField
   * @description validate field of type <input type="url">
   * @param {object} field - Field to validate.
   */
  var validateURLField = function (field) {
    // Check value for required field.
    if (!field.val() && field.prop('required')) {
      displayFieldError(field);
  
      return false;
    }

    // Control format of url
    // See https://gist.github.com/dperini/729294
    var urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i

    if (!urlRegex.test(field.val())) {
      displayFieldError(field);
  
      return false;
    }
  
    resetFieldError(field);
  
    return true;
  } 
  
  /**
   * @function validateEmailField
   * @description validate field of type <input type="email">
   * @param {object} field - Field to validate.
   */
  var validateEmailField = function (field) {
    // Check value for required field.
    if (!field.val() && field.prop('required')) {
      displayFieldError(field);
  
      return false;
    }

    // Control format of email
    // See https://www.w3.org/TR/2012/WD-html-markup-20120315/input.email.html
    var emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (!emailRegex.test(field.val())) {
      displayFieldError(field);
  
      return false;
    }
  
    resetFieldError(field);
  
    return true;
  } 
  
  /**
   * @function validatePasswordField
   * @description validate field of type <input type="password">
   * @param {object} field - Field to validate.
   */
  var validatePasswordField = function (field) {
    var confirmField = (field.data('confirm') ? field.data('confirm') : '');

    // Check password strength
    if (field.hasClass('password-strength') || (confirmField && $('#' + confirmField).hasClass('password-strength'))) {
      // If field is the confirmation field, check strenght on main field.
      fieldToCheckStrength = field;
      if (confirmField && $('#' + confirmField).hasClass('password-strength')) {
        fieldToCheckStrength = $('#' + confirmField);
      }

      // If strength is not enough, throw error.
      var strength = getPasswordStrength(fieldToCheckStrength);
      if (strength !== 'strong') {
        displayFieldError(field, 'strength');

        if (confirmField) displayFieldError($('#' + confirmField), 'strength');
    
        return false;
      }
    }

    // Bypass controls on confirmation field.
    if (!confirmField) {
      return true
    }
  
    // Check value for required field.
    if (!field.val() && field.prop('required')) {
      displayFieldError(field);
  
      if (confirmField) displayFieldError($('#' + confirmField));
  
      return false;
    }

    // Check that field and its confirmation have the same value.
    if (confirmField && field.val() !== $('#' + confirmField).val()) {
      displayFieldError(field, 'confirmation');
  
      displayFieldError($('#' + confirmField), 'confirmation');
  
      return false;
    }
  
    resetFieldError(field);
  
    if (confirmField) resetFieldError($('#' + confirmField));
  
    return true;
  }

  validateCheckboxes = function (group) {
    if (group.find('input:checked').length === 0) {
      group.find('input').addClass('is-invalid');
      group.find('.invalid-feedback').show();

      return false;
    }

    group.find('input').removeClass('is-invalid');
    group.find('.invalid-feedback').hide();

      return true;
  }
  
  /**
   * @function validateField
   * @description validate field depending on type
   * @param {object} field - Field to validate.
   */
  validateField = function (field) {
    if (field.is('input')) {
      switch (field.attr('type')) {        
        case 'email': 
          return validateEmailField(field);
          break;

        case 'url': 
          return validateURLField(field);
          break;
  
        case 'password':
          return validatePasswordField(field);
          break;
  
        default:
          return validateInputField(field);
          break;
      }    
    }
    else if (field.is('select')) {
      return validateSelectField(field);
    }
    else if (field.is('textarea')) {
      return validateTextareaField(field);
    }
  }

  /**
   * @function getPasswordStrength
   * @description check password strength
   * @param {object} field - Password field to check.
   */
  getPasswordStrength = function (field) {
    // Init Regex to test strength
    var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
    var enoughRegex = new RegExp("(?=.{6,}).*", "g");

    var strength;

    // Get password strength
    if (false === enoughRegex.test(field.val())) {
      strength = 'poor';
    } else if (strongRegex.test(field.val())) {
      strength = 'strong';
    } else if (mediumRegex.test(field.val())) {
      strength = 'medium';
    } else {
      strength = 'weak';
    }

    // Display password field depending on its strength
    field.parents('.form-group').find('small.password-strength-text').hide();
    field.parents('.form-group').find('small.password-strength-text.' + strength).css('display', 'block');

    if (strength !== 'strong') {
      field.parents('.form-group').find('small.password-strength-label').addClass('error');
    } else {
      field.parents('.form-group').find('small.password-strength-label').removeClass('error');
    }

    field.addClass('strength-' + strength);

    return strength;
  }
  
  /**
   * @function displayInvalidFeedback
   * @description display alert next to invalid field.
   * @param {object} field - Invalid field.
   * @param {string} feedback - Data attributes suffix containing text to display.
   */
  var displayInvalidFeedback = function (field, feedback) {
    if (!feedback) feedback = 'default';

    var invalidFeedback = field.next('.invalid-feedback');
 
    if (invalidFeedback) {
      if (invalidFeedback.data(feedback)) {
        invalidFeedback.text(invalidFeedback.data(feedback));
      } else if (invalidFeedback.data('default')) {
        invalidFeedback.text(invalidFeedback.data('default'));
      }
    }
  }

  // Reset error state on input
  $(document).on('change', '.is-invalid', function (e) {
    $(this).removeClass('is-invalid');
  });

  $(document).on('change', '.form.with-errors input', function (e) {
    $(this).parents('.form.with-errors').removeClass('with-errors');
  });

  // Check input on lost focus
  $(document).on('blur', '.form-control', function (e) {
    validateField($(this));
  });

  // Check password strength on input
  $(document).on('keyup', '.form-control.password-strength', function (e) {
    getPasswordStrength($(this));
  });
});