jQuery(document).ready(function($){
  /**
   * @function interactionsOnPageCatalog
   * @description implements interactions on page Catalog.
   */
  var interactionsOnPageCatalog = function () { 
    /** 
     * @type {Element}
     * Element for <div> behind navigation bar to highlight active category.
     */
    var currentControl = document.querySelectorAll('#catalog-switch-sticky .current-control').item(0);
    /** 
     * @type {number}
     * Indicates index of active category.
     */
    var currentControlOffsetLeftCurrent = 0;

    /** 
     * @type {HTMLCollection}
     * Collection of HTML Elements associated to categories.
     */
    var categories = document.getElementsByClassName('api-category');
    /** 
     * @type {number}
     * Defaut padding top above category's title.
     */
    var categoriesPaddingTop = 75;
    /** 
     * @type {number[]}
     * Init list of categories' offset top.
     */
    var categoriesOffsetTop = [];

    // Get categories's offset top and store them ordered in dedicated array
    [].forEach.call(categories, function (category) {
      categoriesOffsetTop.push(Number(category.offsetTop) - categoriesPaddingTop);
    });
    categoriesOffsetTop.sort(function(a, b) { return a > b; });

    /**
     * @function displayCurrentCategoriesAsActiveInNavBar
     * @description highlight active category in navigation bar.
     */
    var displayCurrentCategoriesAsActiveInNavBar = function () {
      var offsetLeft = 0;
      
      // Category by category, check if scroll position is in its content.
      for (var count in categoriesOffsetTop) {
        var offsetTop = categoriesOffsetTop[count];

        if (window.pageYOffset >= offsetTop) {
          offsetLeft = count;
        }
      };

      // If active category has changed, highlights it
      if (offsetLeft !== currentControlOffsetLeftCurrent) {
        document.querySelectorAll('#catalog-switch-sticky .anchor.active').item(0).classList.remove('active');
        document.querySelectorAll('#catalog-switch-sticky .anchor').item(offsetLeft).classList.add('active');
        currentControl.style.left = offsetLeft * 25 + '%';

        currentControlOffsetLeftCurrent = offsetLeft;
      }
    }

    /**
     * @function navBarSticky
     * @description make navigation bar "sticky" on scroll
     */
    var navBarSticky = function () {
      var navBar = document.getElementById('catalog-switch');
      var navBarStyle = navBar.currentStyle || window.getComputedStyle(navBar);

      var triggerSticky = navBar.offsetTop;

      if (window.pageYOffset >= triggerSticky) {
        navBar.classList.add('sticky');
      } else {
        navBar.classList.remove('sticky');
      }
    }

    // Apply behaviors on scroll
    $(window).scroll(function() {
      navBarSticky();

      displayCurrentCategoriesAsActiveInNavBar();
    });

  }

  /**
   * @function interactionsOnPageWelcome
   * @description implements interactions on login / subscribe components.
   */
  var interactionsOnPageWelcome = function () {
    // Add placeholder to WordPress login form fields
    if ($('form#loginform').length > 0) {
      $('form#loginform .input').each(function (index) {
        $(this).attr('placeholder', $(this).prev('label').text());
      });
    }

    // Display login / subscribe form on click on button
    $(document).on('click','.welcome-form .toggle a', function (e) {
      e.preventDefault();

      var form = $(this).parents('.welcome-form').find('.form');

      if (!form.hasClass('visible')) {  
        $(this).parents('.toggle').hide();

        form.find('form').slideDown({
          complete: function () {
            form.addClass('visible');
          }
        });
      }
    });

    /**
     * @function validateSubscribeFieldsInStep
     * @description validates fields in form step.
     * @param {object} step - Step containing the fields to validate.
     */
    var validateSubscribeFieldsInStep = function (step) {
      var hasErrors = false;

      step.find('.form-control').each(function (index) {
        if (!validateField($(this))) {
          hasErrors = true;
        }
      });

      return !hasErrors;
    }

    // Disable login form button on submit
    $(document).on('submit', 'form#loginform', function (e) {
      $(this).find('#wp-submit').load();
    });

    // Go back to previous step
    $(document).on('click', 'form#subscribe .step .btn-back', function (e) {
      // Prevent form submission / Bypass link default behavior
      e.preventDefault();

      var form = $('form#subscribe');
      var step = $(this).parents('.step');

      // Get previous step
      var currentStep = Number(step.attr('rel'));
      var prevStep = currentStep - 1;

      // Hide current step
      step.hide();

      // Display next step
      form.find('.step[rel=' + prevStep + ']').show();
      
      // Prevent form submission
      return false;
    });

    // Validate fields in subscribe form
    $(document).on('click', 'form#subscribe .step .btn-primary:not(.disabled)', function (e) {
      // Prevent form submission / Bypass link default behavior
      e.preventDefault();

      var form = $('form#subscribe');
      var step = $(this).parents('.step');
      
      // Disable submit button
      var currentLabel;

      if ($(this).is('button')) {
        $(this).load();
      }

      if (!validateSubscribeFieldsInStep(step)) {
        // Fields in step have errors: error are displayed by function validateSubscribeFieldsInStep()

        // Reset button
        if ($(this).is('button')) {
          $(this).reset();
        }
      } else {
        // Fields in step are ok: display next step OR submit form
        if (step.hasClass('last')) {
          // Last step => execute reCAPTCHA.
          //grecaptcha.reset();
          grecaptcha.execute();
        } else {
          // Get next step
          var currentStep = Number(step.attr('rel'));
          var nextStep = currentStep + 1;

          // Hide current step
          step.hide();

          // Display next step
          form.find('.step[rel=' + nextStep + ']').show();
        }
      }
      
      // Prevent form submission
      return false;
    });
  }

  /**
   * @function interactionsOnMenuMobile
   * @description implements interactions on menu on mobile version.
   */
  var interactionsOnMenuMobile = function () {
    $(document).on('click','#header-mobile .menu-touch', function (e) {
      e.preventDefault();

      // Transform link's content
      $('#menu-wrapper').find('a').each(function (index) {
        if ($(this).find('span').length === 0) {
          $(this).html('<span>' + $(this).text() + '</span>');
        }
      });

      // Add link to login / account
      if ($('#menu-mobile-account').length) {
        $('#menu-mobile-account .login').appendTo('#menu-mobile');
        $('#menu-mobile-account').remove();
      }

      // Show/hide menu
      $('#menu-wrapper').toggle();
    });
  }

  if ($('#header-mobile')) {
    interactionsOnMenuMobile();
  }

  if ($('body').hasClass('post-type-archive-api')) {
    interactionsOnPageCatalog();
  }

  if ($('body').hasClass('page-template-welcome')) {
    interactionsOnPageWelcome();
  }
});